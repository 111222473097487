@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

*,
::after,
::before {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

[disabled] {
  opacity: 0.5;
  pointer-events: none;
}

body,
input,
select,
textarea,
option {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  color: #263238;
  resize: none;
}

.jc-sb {
  justify-content: space-between;
}

.link {
  color: #263238;
  text-decoration: none;
  cursor: pointer;
}

.link--nav {
  display: flex;
  align-items: center;
  font-weight: 600;
  margin-bottom: 20px;
  color: #9e9e9e;
  padding: 0 60px;
}

.link--nav:hover {
  color: #000;
}

.link--nav-img {
  width: 30px;
  height: 15px;
  margin-right: 10px;
  background: url("../images/icons/arrow.svg") no-repeat center;
}

.button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.line {
  width: 100%;
  border: none;
  height: 1px;
  background-color: #c8d1dd;
  margin: 30px 0;
}

.title {
  font-weight: 600;

  .icon--delete {
    width: 24px;
    height: 24px;
    margin: 2px 0;
  }
}

.title--flex {
  display: flex;
}

main.main.wrapper {
  min-height: calc(100vh - 80px);
}

.wrapper {
  margin: 0 auto;
}

.wrapper--registration {
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.registration-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 120px;
  padding: 0 60px;
}

.box {
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 600px;
  margin: 0 auto;
  padding: 10px;
}

.box--small {
  padding: 0 50px;
}

.box.text-center {
  text-align: center;
}

.box--small .input {
  margin-bottom: 30px;
}

.box-icon {
  height: 235px;
  margin-top: 60px;
}

.box-img {
  position: absolute;
  top: 90px;
  right: 55px;
  width: 56px;
  height: 56px;
}

.box-logo {
  width: 200px;
  height: 50px;
  background: url("../images/icons/logo.svg") no-repeat center;
  margin-bottom: 50px;
}

.registration-header .box-logo,
.registration-header .link {
  margin: 0;
}

.box-title {
  font-size: 44px;
  margin-bottom: 80px;
}

.box-title--blue {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 25px;
  font-size: 56px;
  color: #0066f2;
}

.box-title--small {
  font-size: 24px;
  margin-bottom: 40px;
}

.box-txt {
  margin-bottom: 65px;
}

.form-wrapper {
  width: 85%;
  margin: 0 auto 0 auto;
}

.form {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.input {
  width: 100%;
}

.input--txt {
  height: 40px;
  border: none;
  border-bottom: 1px solid #c8d1dd;

  &.form__el-textarea {
    padding: 8px 0;
    overflow: hidden;

    &.comment {
      border: 1px solid #c8d1dd;
      padding: 15px;
      min-height: 196px;
      border-radius: 8px;
    }
  }
}

.field__photo-container {
  display: flex;
  align-items: center;
  gap: 30px;

  .field__photo {
    height: 70px;
    width: 70px;
    background-size: cover;
    background-position: center;
    border-radius: 8px;
    overflow: hidden;
  }

  .form__photo-input {
    text-align: center;
    height: 48px;
    padding: 12px;
    width: 100%;
    max-width: 230px;

    input {
      display: none;
    }
  }
}

.input--select {
  position: relative;
  display: block;
}

.input--radio {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.input--currency {
  display: block;
  width: 170px;
}

.input--searcher,
.input--currency {
  font-size: 14px;
  height: 37px;
  width: 330px;
  padding: 8px 37px 8px 49px;
  border: 1px solid #cfd8dc;
  box-sizing: border-box;
  border-radius: 5px;
}

.input--number {
  padding: 0 10px;
}

.txt--error-block .input {
  border-color: #d94a56;
}

.input--btn {
  cursor: pointer;
  height: 55px;
  border: none;
  border-radius: 5px;
  background-color: #0066f2;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 600;
  color: #fff;

  &.small {
    height: 35px;
    padding: 0 20px;
    font-size: 14px;
  }
}

.input--btn:hover {
  background-color: #0053f0;
}

.input--btn:disabled {
  background-color: #b9caeb;
}

.input--btn--plus,
.input--btn--export {
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 24px;
  padding: 12px 34px;
  width: auto;
  height: 48px;
}

.input--btn--export::before,
.input--btn--plus::before {
  content: "";
  display: inline-block;
  margin-right: 15px;
  width: 14px;
  height: 14px;
  background: url("../images/icons/business_structure/plus.svg") no-repeat
    center;
  background-size: contain;
}

.input--btn--export::before {
  background-image: url("../images/icons/export.svg");
}

.input--btn--light {
  background-color: #ffffff;
  border: 1px solid #0053f0;
  color: #0053f0;
}

.input--btn--light:hover {
  color: #ffffff;
}

.input--checkbox {
  width: 15px;
  height: 15px;
  margin-right: 5px;
}

.form__message {
  width: 100%;
  margin-bottom: 30px;
  padding: 15px 20px;
  background: #f6f6f6;
  border-radius: 8px;
  font-size: 12px;
}

.form__el {
  position: relative;
  margin-bottom: 45px;
  display: flex; // danger
  flex-direction: column; // danger
  align-items: flex-start; // danger
}

.form__el-radio {
  display: flex;
  justify-content: flex-start;
  font-size: 12px;
  padding: 20px 0;
}

.form__el-radio-label {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-right: 35px;
}

.form__el-radio-label--checked {
  font-weight: 600;
}

.form__el-eye,
.form__el-pencil,
.form__el-error-pic,
.form__el-btns,
.form__el-btns-input {
  position: absolute;
  right: 0;
  top: 10px;
}

.form__el-btns-input {
  top: 0;
}

.form__el-eye,
.form__el-pencil,
.form__el-error-pic,
.message-errror__pic {
  width: 25px;
  height: 25px;
}

.form__el-status {
  padding: 7px 0;
}

.form__el-message {
  display: flex;
  justify-content: space-between;
  padding: 7px 0;

  &.type-password {
    width: 100%;
  }
}

.form__el-message--rightside {
  justify-content: flex-end;
  padding-top: 30px;
  font-size: 14px;
}

.form__el-message--leftside {
  justify-content: flex-start;
  padding-top: 30px;
  font-size: 16px;
}

.form__el-message--leftside .link {
  padding-left: 10px;
}

.form__el-eye {
  background: url("../images/icons/eye.svg") no-repeat center;
  cursor: pointer;
}

.form__el-eye--checked {
  background: url("../images/icons/eye-checked.svg") no-repeat center;
}

.form__el-pencil {
  background: url("../images/icons/pencil.svg") no-repeat center;
  cursor: pointer;
}

.form__el-btns {
  display: flex;
  justify-content: flex-end;
}

.form__el-btns .form__el-pencil {
  margin-left: 10px;
}

.form__el-btns .form__el-eye,
.form__el-btns .form__el-pencil {
  position: relative;
  top: 0;
}

.form__el-error {
  font-size: 12px;
  color: #d94a56;
}

.form__el-error-pic,
.message-errror__pic {
  background: url("../images/icons/error.svg") no-repeat center;
}

.form__el-headline {
  font-weight: 600;
  font-size: 18px;
  line-height: 150%;
  text-transform: uppercase;
  color: #64768f;
  margin-bottom: 45px;
  display: flex;
  align-items: center;
}

.form__el-headline [aria-current] {
  display: flex;
  margin-left: 15px;
}

.form__el-headline [aria-current] img {
  width: 16px;
  height: 16px;
}

.form__el-input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.form__el-footer {
  display: flex;
  justify-content: flex-end;

  span {
    font-size: 12px;
    color: #c8d1dd;
  }
}

.form__el-label {
  pointer-events: none;
  position: absolute;
  top: 0.7em;
  left: 0;
  color: #cfd8dc;
}

.form__el-verification {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  width: -moz-fit-content;
  padding: 15px 60px 0 0;
}

.form__el-verification.disabled {
  pointer-events: none;
  opacity: 0.7;
}

.form__el-verification .input {
  width: 52px;
  height: 52px;
  border: 1px solid #c8d1dd;
  border-radius: 8px;
  text-align: center;
  font-weight: 500;
  font-size: 36px;
  color: #0066f2;
}

.form__el-verification .input:not(:last-child) {
  margin-right: 10px;
}

.form__el-verification-valiable {
  position: absolute;
  right: 0;
  top: 30px;
  width: 25px;
  height: 25px;
}

.input:focus {
  outline: none;
  border-color: #0066f2;
}

.form__el--disabled {
  pointer-events: none;
}

.input:focus + .form__el-label,
.form__el--filled .form__el-label {
  top: -1.5em;
  color: #0066f2;
  font-size: 12px;
}

.form__el--filled .form__el-label {
  color: #c8d1dd;
}

.form__btns {
  display: flex;
  justify-content: space-between;
}

.form__btns .input--btn {
  width: 49%;
}

.searcher {
  display: flex;
  align-items: center;
  position: relative;
}

.searcher-loupe {
  position: absolute;
  left: 15px;
  width: 21px;
  height: 21px;
  outline: none;
  border: 0;
  cursor: pointer;
  background-color: transparent;
}

.searcher-loupe img {
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}

.page-controller {
  display: flex;
  align-items: center;
}

.page-controller .break {
  list-style-type: none;
  margin: 0 5px;
}

.page-controller .link {
  color: #64768f;
}

.page-controller__num {
  display: flex;
  align-items: center;
  margin: 0 5px;
  cursor: pointer;
  list-style-type: none;
}

.page-controller__num--active .link {
  background: rgba(0, 102, 242, 0.1);
  border: 1px solid #b9caeb;
  border-radius: 5px;
  color: #0066f2;
}

.page-controller__num .link {
  display: block;
  width: 30px;
  text-align: center;
  line-height: 30px;
}

.page-controller__num--next {
  margin-left: 10px;
}

.page-controller__num--prev {
  margin-right: 10px;
}

.txt--small {
  font-size: 12px;
  color: #64768f;
}

.txt--blue {
  font-weight: 600;
  color: #0066f2;
}

.txt--black {
  color: #263238;
}

.txt--orange {
  background-color: #ff7e33;
  text-transform: uppercase;
  font-weight: 500;
}

.txt--error {
  padding: 7px 0;
  color: #d94a56;
}

.message-errror {
  margin-bottom: 45px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  color: #e34040;
}

.message-errror a {
  margin: 0 4px;
}

.box--small-error .box-title--small {
  margin-bottom: 75px;
}

.box--small-error .message-errror {
  top: 160px;
}

.message-errror__pic {
  margin-right: 10px;
}

.block-mail {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 70px;
}

.block-mail--small {
  width: 60%;
  text-align: start;
}

.block-mail__pic {
  width: 50px;
  height: 40px;
  background: url("../images/icons/mail.svg") no-repeat center;
  background-size: cover;
  margin-bottom: 35px;
}

.block-mail__title {
  margin-bottom: 35px;
  font-size: 24px;
  color: #0066f2;
}

.box-bottom {
  margin-top: auto;
  width: 600px;
  text-align: center;
  padding-top: 30px;
}

.box-bottom__txt {
  padding: 20px 0;
}

.body-grey {
  position: fixed;
  z-index: 10;
  width: 100%;
  height: 100vh;
  background-color: #f6f6f6;
}

.alert {
  position: fixed;
  z-index: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background-color: #adb1b780;
}

.alert-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 605px;
  padding: 30px 55px;
  background-color: #ffffff;
  border-top: 8px solid #0066f2;
  border-radius: 8px;
  overflow: hidden;
  text-align: center;
}

.alert-picture {
  display: flex;
  position: relative;
  border-radius: 8px;
  overflow: hidden;

  img {
    width: auto;
    height: auto;
    max-width: 600px;
    max-height: 400px;
  }
}

.alert-block__img {
  width: 50px;
  height: 50px;
  margin-bottom: 50px;
}

.alert-block__title {
  text-align: center;
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 20px;
  color: #0066f2;
}

.alert-block__btns {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.alert-block__txt {
  margin-bottom: 40px;
}

.alert-block__btns .input {
  width: 49%;
}

.header {
  background-color: #0066f2;
}

.header,
.main {
  position: relative;
  z-index: 20;
}

.header-box,
.main {
  padding: 30px;
}

.header-box__logo {
  display: block;
  width: 110px;
  height: 20px;
  background: url("../images/icons/logo-white.svg") no-repeat center;
}

.main {
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.main-menu {
  position: relative;
  padding: 30px 0 30px 30px;

  .collapse {
    width: -moz-fit-content !important;
    width: fit-content !important;
    padding: 0;
    margin: 10px 0 20px 0;

    img {
      margin: 0;
    }
  }
}

.main-menu--isclose .main-menu__link {
  width: 50px;
}

.main-menu--isclose .main-menu__header {
  justify-content: center;
}

.main-menu--isclose .main-menu__footer-close img {
  transform: rotate(180deg);
}

.main-menu__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 30px;
  font-size: 8px;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 3px;
}

.main-menu__header-close,
.alert-picture__close {
  cursor: pointer;
  width: 10px;
  height: 10px;
  background: url("../images/icons/close.svg") no-repeat center;
}

.alert-picture__close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 14px;
  height: 14px;
}

.main-menu__footer {
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.main-menu__footer-settings:hover {
  color: #263238;
  font-weight: 600;
}

.main-menu__footer-settings:hover .main-menu__link-block {
  display: none;
}

.main-menu__footer-settings:hover .main-menu__link-block--active {
  display: block;
}

.main-menu__link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 225px;
  line-height: 16px;
  padding: 10px 0;
  margin-bottom: 10px;
  color: #b9caeb;
}

.main-menu__link-block,
.main-menu__link-block--active,
.settings-link__img,
.settings-link__img--active {
  width: 24px;
  height: 24px;
  margin-right: 15px;
}

.main-menu__link-block--active {
  display: none;
}

.main-menu__link--active {
  color: #263238;
  font-weight: 600;
  border-right: 4px solid #0066f2;
}

.main-menu__link--active .main-menu__link-block {
  display: none;
}

.main-menu__link--active .main-menu__link-block--active {
  display: block;
}

.main-menu__settings {
  position: absolute;
  bottom: 0;
}

.main-menu__settings .settings {
  display: none;
}

.main-menu__settings--active .settings {
  display: block;
}

.main-menu__settings--active .main-menu__settings-link,
.main-menu__settings--active .main-menu__footer-settings {
  display: none;
}

.main-header,
.main-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px 0;
  font-size: 14px;
  line-height: 37px;
  color: #64768f;
}

.main-header {
  padding: 15px;
  margin-bottom: 15px;
  background-color: #ffffff;
  border-radius: 5px;
}

.main-header__filters,
.main-header__searcher,
.main-footer__number,
.main-footer__pages {
  display: flex;
  align-items: center;
}

.popup {
  margin-right: 15px;
  position: relative;
  height: 37px;
  border: 1px solid #c8d1dd;
  border-radius: 5px;
  font-size: 14px;
  padding: 0 10px;
  display: flex;
  width: auto;
  min-width: 200px;
  align-items: center;

  &.disabled {
    pointer-events: none;
  }
}

.popup .input--btn {
  width: 100px;
  padding: 0;
  font-size: 14px;
}

.popup .label {
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 600;
  line-height: 37px;
  color: #29354b;
}

.popup .label-btn {
  cursor: pointer;
  width: 15px;
  height: 15px;
  background: url("../images/icons/arrow-down-input.svg");
  margin-left: 10px;
}

.popup .input-count {
  width: 17px;
  text-align: center;
  font-size: 12px;
  line-height: 17px;
  color: #ffffff;
  background-color: #0066f2;
  border-radius: 3px;
  margin-left: 10px;
}

.popup.range {
  border: 0;
  padding: 0;
  min-width: 100px;

  .input--cost {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 37px;

    &:hover {
      background-color: #ffffff;
      color: #0053f0;
    }

    &:before {
      content: "";
      display: inline-block;
      width: 16px;
      height: 16px;
      margin-right: 10px;
      background: url(../images/icons/table/filter.svg) no-repeat;
    }
  }
}

.popup-menu {
  min-width: 170px;
}

.popup--active {
  position: relative;
}

.popup--active .popup-menu {
  display: block;
}

.popup--active .label-btn,
.popup--active .table-status::after {
  transform: rotate(180deg);
}

.popup-menu {
  display: none;
  position: absolute;
  left: 0;
  top: calc(100% + 10px);
  z-index: 900;
  background-color: #ffffff;
  color: #263238;
  border-top: 8px solid #0066f2;
  border-radius: 8px;
  box-shadow: 0px 5px 20px 0px rgba(34, 60, 80, 0.2);
  overflow: hidden;
  min-width: 100%;
}

.popup-menu--date {
  padding: 25px;
  right: auto;
}

.popup-menu--range {
  padding: 30px;
}

.popup-menu--range .popup-menu__bottom-control {
  margin-bottom: 20px;
}

.popup-menu__item {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0 10px;
  font-size: 14px;
  line-height: 35px;
}

.input--checkbox:checked + .txt {
  color: #0066f2;
  font-weight: 600;
}

.popup-menu__item:hover {
  background: rgba(0, 95, 234, 0.1);
  color: #0066f2;
}

.popup-menu__bottom {
  display: flex;
  justify-content: space-between;
}

.popup-menu__bottom-control {
  display: flex;
}

.popup-menu__bottom .input {
  height: 37px;
}

.popup-menu__bottom-control .input {
  width: 90px;
  margin-right: 10px;
  padding: 0 10px;
}

.popup-menu__bottom-control .input:last-child {
  margin: 0;
}

.popup-menu__bottom-control .input--currency {
  width: 100px;
}

.popup-menu__bottom-control .img {
  margin-right: 10px;
}

.main-table__main-status .popup-menu {
  left: 0;
  min-width: calc(100% - 10px);
}

.main-footer .input--number {
  width: 65px;
  margin: 0 15px;
  background: #ffffff;
  border: 1px solid #c8d1dd;
  box-sizing: border-box;
  border-radius: 5px;
  height: 37px;
  font-size: 14px;
  line-height: 21px;
  color: #29354b;
  text-align: center;
}

.transaction--amount {
  display: flex;
  align-items: center;

  img {
    width: 9px;
    height: 16px;
    margin-right: 4px;
  }
}

.table--sider {
  display: flex;

  .main-table {
    flex: 1;
    width: auto;
  }

  .sider {
    width: 400px;
    min-height: 630px;
    background-color: #ffffff;
    border-radius: 8px;
    margin-left: 30px;
    display: flex;
    flex-direction: column;
    padding: 30px;

    .sider--head {
      margin-bottom: 15px;
      font-weight: 600;
      font-size: 18px;
    }

    .sider--row {
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 15px;
      font-weight: 400;
      font-size: 14px;

      span {
        margin-right: 10px;
        color: #64768f;
      }

      .sider--row--status {
        width: 10px;
        height: 10px;
        border-radius: 50%;
        margin-right: 5px;

        background-color: #47a04b;
      }
    }
  }
}

.main-table {
  padding: 10px 30px !important;
  min-height: 0;
}

.main-table .table--main {
  overflow: auto;
  height: 600px;
}

.main-table table {
  width: 100%;
  border-collapse: collapse;
}

.main-table thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  font-size: 12px;
  text-transform: uppercase;
  color: #c8d1dd;
  text-align: left;
  line-height: 18px;
  padding: 15px 0;
  background-color: #fff;
  box-shadow: inset 0px -1px #c8d1dd;
}

.main-table tbody tr {
  font-size: 12px;
  line-height: 18px;
}

.main-table tbody tr:hover {
  background-color: #fbfcff;
  cursor: pointer;
}

.main-table tbody tr:not(:last-child) {
  border-bottom: 1px solid #c8d1dd;
}

.main-table tbody tr td {
  color: #263238;
  padding: 30px 15px 30px 0;

  &:last-child {
    padding-right: 0;
  }
}

.main-table tbody tr td[colspan] {
  text-align: center;
}

.main-table__main-name-container {
  display: flex;
  align-items: center;

  .main-table__main-name-img {
    display: inline-block;
    width: 38px;
    height: 38px;
    margin-right: 10px;
    border-radius: 8px;
    background-color: #e5e5e5;
    overflow: hidden;

    img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
      cursor: pointer;
    }
  }

  .txt {
    white-space: nowrap;
  }
}

.table-status {
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.link.table-status::after {
  content: "";
  width: 15px;
  height: 15px;
  margin: 0 0 0 15px;
  background: url(../images/icons/arrow-down-input.svg);
}

.table-status::before,
.popup-menu__status::before {
  content: "";
  display: inline-block;
  width: 10px;
  height: 10px;
  margin-right: 10px;
  border-radius: 50%;
}

.table-status--new::before,
.input--btn--status-new {
  background-color: #0066f2;
}

.table-status--on-review::before,
.input--btn--status-on-review {
  background-color: #ff7e33;
}

.table-status--need-info::before,
.input--btn--status-need-info {
  background-color: #ffe600;
}

.table-status--approved::before,
.input--btn--status-approved {
  background-color: #47a04b;
}

.table-status--rejected::before,
.input--btn--status-rejected {
  background-color: #d94a56;
}

.main-table__header-link::after {
  content: "";
  display: inline-block;
  width: 6px;
  height: 10px;
  margin: 0 5px;
  background: url("../images/icons/table/arrow-double-table.svg") no-repeat;
}

.main-table__main-status {
  position: relative;

  &.disabled {
    pointer-events: none;
  }
}

.icon--delete {
  width: 12px;
  height: 12px;
  background: url("../images/icons/table/bin.svg") no-repeat;
  background-size: cover;
  border: none;
  cursor: pointer;
}

.table.products {
  tr {
    display: grid;
    grid-template-columns: calc(15% - 12px) 15% 15% 15% 15% 15% 10% 12px;

    &:hover {
      cursor: pointer;
    }
  }
}

.settings {
  position: absolute;
  bottom: 75px;
  width: 250px;
  padding-bottom: 10px;
  background-color: #ffffff;
  border: 1px solid #c8d1dd;
  border-radius: 8px;
  overflow: hidden;
  z-index: 1;
}

.settings-header {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 15px;
  margin-bottom: 10px;
  border-bottom: 1px solid #c8d1dd;
}

.settings-header__photo {
  width: 56px;
  height: 56px;
  margin-right: 10px;
  background-color: #ebf3ff;
  background-image: url("../images/icons/settings/profile.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: 40%;
  border-radius: 50%;
}

.settings-header__info {
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.settings-header__info-name {
  font-size: 14px;
}

.settings-link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 10px 15px;
  color: #64768f;
}

.settings-link__img--active {
  display: none;
}

.settings-link:hover {
  background-color: #f6f6f6;
  color: #263238;
}

.settings-link:hover .settings-link__img {
  display: none;
}

.settings-link:hover .settings-link__img--active {
  display: block;
}

.main-box {
  width: 100%;
  padding: 30px;
  overflow: auto;
  max-height: calc(100vh - 80px);
}

.main-box__bread-crumbs {
  font-size: 10px;
  color: #64768f;
  margin-bottom: 30px;
}

.main-box__bread-crumbs a {
  font-size: 10px;
  color: #64768f;
  text-decoration: none;
  margin: 0 5px;
}

.main-box__bread-crumbs a:first-child {
  margin-left: 0;
}

.main-box__title {
  margin-bottom: 30px;
  font-size: 24px;
  font-weight: 600;
  color: #0066f2;
}

.main-box__title--flex {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.main-box__title--flex .input--btn--plus {
  width: 100%;
  max-width: 210px;
  text-decoration: none;
}

.main-box__title--flex .popup {
  display: flex;
  background-color: #fff;
}

.main-box__title--flex .label-btn {
  right: 10px;
}

.main-box__title--flex .input-count {
  right: 30px;
}

.main-box__title .title {
  font-size: 24px;
}

.main-content {
  width: 100%;
  min-height: 630px;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 30px 35px;
}

.table--main::-webkit-scrollbar,
.main-content::-webkit-scrollbar {
  width: 25px;
}

.table--main::-webkit-scrollbar-track,
.main-content::-webkit-scrollbar-track {
  background-color: transparent;
}

.table--main::-webkit-scrollbar-thumb,
.main-content::-webkit-scrollbar-thumb {
  background-color: #c8d1dd;
  border: 10px solid white;
  border-radius: 20px;
}

.main-content__container {
  height: 0;
  overflow: hidden;
  transition: 0.5s;

  &.main-content__container__active {
    overflow: visible;
    height: auto;
  }
}

.PhoneInputInput {
  height: 40px;
  border: 0;
  background-color: transparent;
  outline: none;
}

.main-content--sole-proprietorship .main-content__container--second,
.main-content--sole-proprietorship .main-content__img,
.main-content__img--sole-proprietorship {
  display: none;
}

.main-content--sole-proprietorship .main-content__img--sole-proprietorship {
  display: block;
}

.main-content--bricks {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: wrap;
  background-color: #f6f6f6;
  margin: -15px;
  padding: 0;
  width: calc(100% + 30px);
}

.main-content--bricks--small,
.main-content--bricks--big,
.main-content--bricks--medium,
.main-content--bricks--max {
  background-color: #ffffff;
  border-radius: 8px;
  padding: 30px;
  margin: 15px;
  flex: 1;
  min-width: 40%;
}

.main-content--bricks--big.underwriter {
  min-width: 60%;
  padding: 60px;
}

.underwriter .bricks-content-txt {
  margin: 0;
}

.main-content--bricks--small {
  max-width: 400px;
  min-width: auto;
  width: 100%;
}

// .main-content--bricks--medium {
//   width: 48%;
// }

.main-content--bricks--max {
  width: 100%;
  padding: 60px;
}

.main-content--bricks--max .bricks-content-title {
  margin-bottom: 30px;
}

.main-content--bricks--max .bricks-content-txt {
  margin: 0;
}

.main-content__colums {
  display: flex;
  flex-wrap: wrap;
  gap: 25px;

  &.comments {
    margin-bottom: 25px;
  }

  .title {
    margin-bottom: 20px;
  }

  .line {
    margin: 5px 0;
  }
}

.main-content__colums-item {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 33%;
  font-size: 14px;
  color: #64768f;

  &.full {
    width: 100%;
  }

  .txt--blue {
    margin-right: 8px;
  }

  &.full {
    width: 100%;
    margin: 0;
  }

  .image {
    display: flex;
    border-radius: 8px;
    overflow: hidden;
    width: 70px;
    height: 70px;
    background-size: cover;
    background-position: center;
    margin-bottom: 30px;
  }
}

.main-content__colums-item--bottom {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.main-content__colums .title,
.main-content__item .link {
  font-size: 18px;
  font-weight: 600;
  color: #263238;
  background: transparent;
  border: 0;

  &.link--active {
    border-bottom: 2px solid #0066f2;
    padding: 0 0 8px 0;
  }
}

.main-content__item .title--switch {
  display: flex;
  gap: 52px;

  button {
    font-family: "Poppins", sans-serif;
    padding: 0 0 10px 0;
    margin-bottom: 30px;
  }
}

.main-content__colums-item p,
.main-content__colums-item a {
  display: block;
  text-decoration: none;
  width: fit-content;
  width: -moz-fit-content;
}

.bricks-title {
  font-size: 18px;
  color: #64768f;
  margin-bottom: 30px;
}

.bricks-content {
  position: relative;
  font-size: 14px;
}

.bricks-content-img {
  position: absolute;
  top: 75px;
  left: 265px;
}

.bricks-content-title {
  display: flex;
  flex-direction: column;
  font-size: 36px;
  margin-bottom: 15px;
  position: relative;
}

.bricks-content-title__img {
  width: 45px;
  position: absolute;
  left: 200px;
  top: 15px;
}

.bricks-content-txt {
  margin-bottom: 35px;
  line-height: 30px;
}

.bricks-btn {
  width: 245px;
}

.graph-money,
.bricks-content__money {
  font-size: 36px;
  margin-bottom: 15px;
}

.bricks-content__money {
  margin-bottom: 40px;
}

.bricks-content__status {
  margin-bottom: 30px;
}

.bricks-content__links {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  height: 50px;
  border-top: 1px solid #c8d1dd;
}

.graph {
  padding-top: 30px;
  text-align: center;
}

.graph-status {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  color: #64768f;
}

.graph-status__img {
  width: 10px;
  height: 20px;
  margin-right: 10px;
}

.graph-status__img--negative {
  display: none;
}

.graph-status--negative .graph-status__img--negative {
  display: block;
}

.graph-status--negative .graph-status__img--positive {
  display: none;
}

.graph-body {
  position: relative;
  min-height: 150px;
}

.graph-body__bottom {
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  width: 100%;
  font-weight: 600;
  font-size: 12px;
  text-transform: uppercase;
  color: #64768f;
  line-height: 45px;
  border-top: 1px solid #c8d1dd;
}

.main-content__title {
  font-size: 36px;
  font-weight: 600;
  color: #263238;
  margin-bottom: 30px;
}

.main-content__title--flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-content__title-item {
  display: flex;
  align-items: center;
}

.main-content__title-item .txt {
  margin-right: 30px;
  white-space: nowrap;
}

.main-content__title-item .txt--orange {
  padding: 0 15px;
  margin-left: 20px;
  font-size: 14px;
  line-height: 40px;
  color: #ffffff;
  border-radius: 8px;
}

.main-content__title-item .input:not(button) {
  pointer-events: none;
  height: 40px;
  padding: 0 15px;
}

.main-content__block {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 130px;
}

.main-content__block-info {
  width: 35%;
}

.main-content__block-info .txt {
  margin-bottom: 30px;
}

.main-content__block-info-img {
  position: relative;
}

.main-content__block-info-img-btns {
  position: absolute;
  left: 140px;
  bottom: 0;
  display: flex;
  justify-content: space-between;
  width: 60px;
}

.main-content__block-form {
  width: 65%;
  padding: 20px 80px 0 25px;
}

.main-content__form,
.product-add__form {
  width: 100%;
  max-width: 495px;

  &.comment {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    max-width: 100%;

    .input--btn {
      width: auto;
    }

    .form__el {
      margin-bottom: 30px;
      width: 100%;
    }
  }
}

.main-content__form .form__el:last-child {
  margin: 0;
}

.main-content__form .input--txt {
  padding-right: 70px;
}

.status-bar {
  position: relative;
  width: 100%;
  height: 7px;
  border-radius: 8px;
  overflow: hidden;
}

.status-bar__gradient {
  position: relative;
  z-index: 100;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    #d94a55 0%,
    #e26e40 15%,
    #ea9129 30%,
    #f2b917 45%,
    #efd507 60%,
    #e6eb06 75%,
    #a2d133 90%,
    #25ad4b 100%
  );
}

.status-bar__progress {
  position: absolute;
  z-index: 200;
  top: 0px;
  right: 0;
  height: 100%;
  background-color: #ffffff;
  box-shadow: 0px 0px 0px 1px #c8d1dd inset;
  -webkit-border-top-right-radius: 8px;
  -webkit-border-bottom-right-radius: 8px;
  -moz-border-radius-topright: 8px;
  -moz-border-radius-bottomright: 8px;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
}

.form__el-input,
.form__el-label,
.link--nav,
.settings-link,
.input--btn {
  -webkit-transition: all 0.2s;
  transition: all 0.2s;
}

.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  margin: 30px 0;
  transform: scale(1.1);
}

.lds-default div {
  position: absolute;
  width: 3px;
  height: 3px;
  background: #0066f2;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}

.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}

.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}

.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}

.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}

.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}

.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}

.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}

.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}

.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}

.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}

.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}

.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}

@keyframes lds-default {
  0% {
    transform: scale(3);
  }
  100% {
    transform: scale(1);
  }
}

.popup-menu {
  .react-calendar {
    border: 0;

    .react-calendar__month-view__days {
      button {
        border-radius: 8px;
        flex-basis: calc(100% / 7 - 4px) !important;
        margin: 2px;
      }
    }

    .react-calendar__decade-view__years,
    .react-calendar__year-view__months,
    .react-calendar__century-view__decades {
      button {
        border-radius: 8px;
        flex-basis: calc(100% / 3 - 4px) !important;
        margin: 2px;
      }
    }
  }

  .react-calendar__navigation button:enabled:focus {
    background-color: initial;
  }

  .react-calendar__navigation button:enabled:hover {
    background-color: #e6f0ff;
  }

  .react-calendar__tile:enabled:hover,
  .react-calendar__tile:enabled:focus {
    background-color: #e6f0ff;
  }

  .react-calendar--selectRange .react-calendar__tile--hover {
    background-color: #e6f0ff;
  }

  .react-calendar__tile--active {
    background: #e6f0ff !important;
    color: black !important;

    &.react-calendar__tile--rangeEnd,
    &.react-calendar__tile--rangeStart {
      color: white !important;
      background: #0066f2 !important;
    }
  }

  .react-calendar__tile--hasActive {
    color: white !important;
    background: #0066f2 !important;
  }

  .react-calendar__navigation button {
    margin: 0 2px;
    border-radius: 8px;
  }
}

input[type="number"] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
